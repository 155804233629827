<template>

  <div class="review_item" v-if="props.itemUser && props.itemDiary">
    <div class="user_box">
      <NuxtLink class="avatar" :to="props.itemUser.link">
        <img loading="lazy" :src="props.itemUser.avatar_small">
        <i :class="'user_range u_range_' + props.itemUser.status"></i>
      </NuxtLink>
      <NuxtLink class="name" :to="props.itemUser.link">
        {{ props.itemUser.name }}
      </NuxtLink>
      <span class="status">{{ UserStatusTr[props.itemUser.status] && UserStatusTr[props.itemUser.status] != '-' ? $t(UserStatusTr[props.itemUser.status]) : '' }}</span>
    </div>
    <div class="review_box">
      <div class="review_content">

        <div class="rt">
          <div class="val" v-if="props.strainGeneral">
            <span class="star_rate"></span>
            {{ strainGeneral }}
          </div>
          <NuxtLink class="name_seed" :to="props.itemDiary.link">

            <template v-if="props.itemProduct">
              {{ props.itemProduct.name }}
            </template>
            <template v-else>
              {{ props.itemDiary.name }}
            </template>
          </NuxtLink>

          <div class="setup">

            <div class="rw" v-if="props.strainGrowing">
              <div class="name">{{ $t('profile_view_growing_it') }}</div>
              <div class="value">{{ HarvestSeedPropsGrowingTr[props.strainGrowing] ? $t(HarvestSeedPropsGrowingTr[props.strainGrowing]) : '' }}</div>
            </div>
            
            <div class="rw" v-if="props.strainResistance">
              <div class="name">{{ $t('profile_view_resistance') }}</div>
              <div class="value">{{ HarvestSeedPropsResistanceTr[props.strainResistance] ? $t(HarvestSeedPropsResistanceTr[props.strainResistance]) : '' }}</div>
            </div>
          </div>

          
        </div>

        <div class="text">
          <GeneralShowMore
            :desktop-max-height="60"
            :mobile-max-height="80" 
            >
            {{ props.strainComment }}
            {{ props.comment }}
          </GeneralShowMore>

          <div class="date_create">
            {{ $dayjs(props.addDate).utc().fromNow() }}
          </div>
        </div>

        <div class="rt" v-if="props.itemsProduct"> 
          <div 
            v-for="(item, key) in props.itemsProduct"
            :key="key"
            class="rate_item"
            >
            <div class="val">
              <i class="icon-star"></i>
              {{ item.n_general }}
            </div>
            <NuxtLink class="name_seed" :to="item.link">
              {{ item.name }}
            </NuxtLink>
          </div> 
        </div>


      </div>
    </div>
  </div>

</template>

<script setup lang="ts">

import { HarvestSeedPropsGrowingTr, HarvestSeedPropsResistanceTr } from '@/types/harvest'
import type { HarvestSeedPropsGrowing, HarvestSeedPropsResistance } from '@/types/harvest'

import type { Diary } from '@/types/diary';
import type { Product } from '@/types/product';
import type { User } from '@/types/user';
import { UserStatusTr } from '@/types/user';

interface PageProps {
  id: number;
  comment?: string;
  strainComment?: string;
  strainGeneral?: number;
  postId?: number;
  addDate?: string;
  strainResistance?: HarvestSeedPropsResistance;
  strainGrowing?: HarvestSeedPropsGrowing;
  itemDiary?: Diary;
  itemUser?: User;
  itemsProduct?: Product[];
  itemProduct?: Product;
}

const props = defineProps<PageProps>();
 
</script>


<style scoped>
 
  

.review_item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  contain: content;
}
.review_item .user_box{
  display: inline-block;
  vertical-align: top;
  width: 60px;
  text-align: center;
  margin-right: 20px;
  margin-top: 45px;
  flex-shrink: 0;
}
.review_item .user_box .avatar{
  display: inline-block;
  width: 100%;
  position: relative;
}
.review_item .user_box .avatar img{
  width: 100%;
  border-radius: 200px;
}
.review_item .user_box .avatar .user_range{
  float: left;
  font-size: 1.6rem;
  margin-top: 6px;
  position: absolute;
  right: 0px;
  bottom: 6px;
}
.review_item .user_box .name{
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--un-text-color);
}
.review_item .user_box .status{
  font-size: 0.75rem;
  color: gray;
}

.review_item .review_box{
display: inline-block;
vertical-align: top;
width: 100%;
padding: 10px 0px 10px 10px;
}

.review_item .review_content{
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.review_item .review_box .rt{
  font-weight: bold;
  margin-bottom: 12px;
  display: flex;
  gap: 1px 10px;
  align-items: center;
  flex-wrap: wrap;
}
.review_item .review_box .rt .rate_item{
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;

}
.review_item .review_box .name_seed{
  color: var(--un-text-color);
  font-weight: bold;
  margin-right: auto;
  line-height: 1.2rem;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.review_item .review_box .rt .val{
  display: inline-block;
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 0.75rem;
  background-color: #fcee2533;
  /* font-weight: bold; */
  margin-right: 7px;
  flex-shrink: 0;
}
.review_item .review_box .rt .star_rate{
  width: 13px;
  height: 13px;
}
.review_item .review_box .date_create{
  color: gray;
  font-style: italic;
  margin-top: 5px;
  font-size: 0.75rem;
}
.review_item .review_box .btn_translate{
  color: gray;
  margin-top: 5px;
  font-size: 0.85rem;
  cursor: pointer;
}
.review_item .review_box .text{
  background-color: var(--un-background-color-gray);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  width: 100%;
  overflow-wrap: anywhere;
}

.review_item .review_box .text:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--un-background-color-gray) transparent transparent;
  position: absolute;
  left: -10px;
  top: 21px;
}


.review_item .setup{
  display: flex;  
  gap: 0px 5px; 
  flex-wrap: wrap; 
  width: fit-content;
}
.review_item .rw{
  display: flex;  
  gap: 5px;  
}
.review_item .rw .name{
  display: inline-block;
  vertical-align: top; 
  color: rgba(128, 128, 128, 0.47);

  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.review_item  .rw .value{
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



@container pb (max-width: 768px){

  .review_item .setup{
    justify-content: flex-end;
  }
  .review_item .review_box .rt{
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 3px;
    gap: 5px;
  }
}



</style>
